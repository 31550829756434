// @ts-nocheck
import { useInfiniteQuery, UseInfiniteQueryResult } from "@tanstack/react-query"
import ApiClient from "../lib/api/api-client"
import { Messages } from "@/lib/types"

export default function useMessages(): UseInfiniteQueryResult<Messages> {

  const fetchMessagesByContact = ({pageParam}: {pageParam: string}) => {
    return ApiClient().get(`/messages`, {
      params: {
        after: pageParam,
      }
    })
  }

  return useInfiniteQuery({
    queryKey: ['messages'],
    queryFn: fetchMessagesByContact,
    keepPreviousData: true,
    getNextPageParam: (lastPage: Messages) => {
      return lastPage.meta.page_info.has_next_page ? lastPage.meta.page_info.end_cursor : null
    }
  })
}
