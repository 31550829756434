import { useTrail, animated, config } from '@react-spring/web'
import ChatBubble from '../chat/chat-bubble'

function TypingIndicator() {
  const dots = 3
  const trail = useTrail(dots, {
    config: config.wobbly,
    from: { scale: 1 },
    to: { scale: 0.8 },
    loop: true,
  })

  return (
    <ChatBubble>
      <div className="flex space-x-2">
        {trail.map((props, index) => (
          <animated.div key={index} style={props} className="w-2 h-2 bg-black rounded-full"></animated.div>
        ))}
      </div>
    </ChatBubble>
  )
}

export default TypingIndicator