import { ArrowPathIcon } from "@heroicons/react/24/outline"
import { useThemeContext } from "../contexts/theme-context"
import Dialog from "./ui/dialog"
import { useState } from "preact/hooks"
import Tooltip from "./ui/tooltip"
import { UseMutateAsyncFunction } from "@tanstack/react-query"

export default function StartOverButton({ resetContact } : { resetContact: UseMutateAsyncFunction }) {
  const theme = useThemeContext()
  const [dialogOpen, setDialogOpen] = useState(false)

  return (
    <>
      <Tooltip message="Start Over" position="bottom">
        <button
          className="flex items-center justify-center text-xs px-3 py-2 rounded-full dynamic-hover"
          style={{
            transition: 'color 0.3s ease, background-color 0.3s ease',
            ['--hover-color']: theme.primary_background_color,
            ['--hover-bg-color']: theme.primary_text_color
          }}
          onClick={() => setDialogOpen(true)}
        >
          <ArrowPathIcon className="h-7 w-7 sm:h-5 sm:w-5" />
        </button>
      </Tooltip>

      <Dialog
        isOpen={dialogOpen}
        setIsOpen={setDialogOpen}
        title='Start Over?'
        message='Are you sure you want to start over? This will reset the chat and all data.'
        onConfirm={resetContact}
        onCancel={() => setDialogOpen(false)}
      />
    </>
  )
}