
function CreditsPage() {
  const urlParams = new URLSearchParams(window.location.search)
  const widgetId = urlParams.get('widget_id') || "1"

  return (
    <div className="h-[100vh] w-full">
      <div
        className="absolute inset-x-0 top-0 z-0 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="absolute z-40 right-0 left-0 flex flex-col items-center gap-y-4 justify-center h-full p-4 text-center">
        <img
          src="./assets/imgs/sparkfire-logo.png"
          alt="SparkFire"
          className="w-40 sm:w-96 object-contain"
        />

        <a href={`/terms${location.search}`} className="text-sm px-4 py-2 mt-4 absolute bottom-8 hover:underline" data-native>
          See terms and conditions
        </a>
      </div>
      <div
        className="absolute inset-x-0 bottom-0 z-0 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>

      <script>{`(function(w, d, s, n, t, p) {w[n] = w[n] || function() {(w[n].q = w[n].q || []).push(arguments);};t = d.createElement(s); p = d.getElementsByTagName(s)[0]; t.id = s; t.async = true; t.src = 'https://cdn.sparkfire.ai/chat.js'; t.dataset.widgetId = ${widgetId}; p.parentNode.insertBefore(t, p);})(window, document, 'script', 'sparkfire');`}</script>
    </div>
  )
}

export default CreditsPage