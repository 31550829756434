
function ErrorBox() {
  return (
    <div className="flex flex-col gap-y-1 justify-center mr-10 items-start ml-4">
      <div className='text-sm py-3 px-4 rounded-md bg-rose-200 border-rose-500 text-rose-500'>
        <p>Something went wrong! Please reload or contact support.</p>
      </div>
    </div>
  )
}

export default ErrorBox
