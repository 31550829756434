import useMessages from "../../hooks/use-messages"
import { ArrowPathIcon } from "@heroicons/react/24/outline"
import { useEffect, useState } from "preact/hooks"
import { useInView } from "react-intersection-observer"
import ChatBubble from "./chat-bubble"
import LoadingChat from "../loading/loading-chat"
import ErrorBox from "../ui/error-box"
import { Messages } from "@/lib/types"
import { InfiniteData } from "@tanstack/react-query"
import TypingIndicator from "../ui/typing-indicator"
import { useAppContext } from "../../contexts/app-context"
import FollowUp from "../followup-ui"
import useCurrentContact from "../../hooks/use-current-contact"
import { useMessagesDelayTracker } from "../../hooks/use-messages-delay-tracker"

interface ChatMessagesProps {
  scrollTargetRef: React.RefObject<HTMLDivElement>
  isGeneratingResponse: boolean
}

function checkForInboundMessage(messages: InfiniteData<Messages>) {
  return messages.pages[0].page.find((msg) => { return msg.direction == "in" }) != null
}

function ChatMessages({ scrollTargetRef, isGeneratingResponse }: ChatMessagesProps) {
  const { data: messages, isLoading, error, fetchNextPage, hasNextPage } = useMessages()
  const { setHasInboundMessages, ended, isSmallScreen, channel, isDelayed } = useAppContext()
  const { data: contact, isLoading: isLoadingContact } = useCurrentContact()
  useMessagesDelayTracker(messages, contact)

  const { ref: scrollTriggerRef, inView } = useInView()

  const [firstLoad, setFirstLoad] = useState(true)

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage])

  useEffect(() => {
    if (!messages) return

    if (checkForInboundMessage(messages)) {
      setHasInboundMessages(true)
    } else {
      setHasInboundMessages(false)
    }

    setTimeout(() => {
      scrollTargetRef.current?.scrollIntoView({ behavior: firstLoad ? "auto" : "smooth" })
      if (firstLoad) setFirstLoad(false)
    }, 100)
  }, [messages, scrollTargetRef, isGeneratingResponse, firstLoad, setHasInboundMessages])

  if (error) return <ErrorBox />
  if (isLoading || isLoadingContact) return <LoadingChat />

  return (
    <>
      <ul className={`flex flex-col-reverse gap-y-4 px-4 ${isSmallScreen && 'min-h-[calc(100vh-8rem)] justify-end'}`}>
        {isGeneratingResponse &&
          <TypingIndicator />
        }

        {channel === 'webchat' && (
          <>
            {ended &&
              <FollowUp
                text="Sorry, I can't help you with that. You can leave your contact information below and a team member will contact you."
              />
            }

            {isDelayed &&
              <FollowUp
                text="Sorry, it's taking us a bit longer than usual to reply. Please provide your email and we will follow-up as soon as possible."
              />
            }
          </>
        )

        }

        {channel === "sms" && (
          <>
            {contact.needs_review &&
              <div className="p-1">
                <p className="text-sm italic">This conversation needs review.</p>
              </div>
            }

            {ended &&
              <div className="p-1">
                <p className="text-sm italic">This conversation has been ended.</p>
              </div>
            }
          </>
        )}

        {messages.pages.length == 0 ?
          <p>No messages yet</p>
          :
          messages.pages.map((page) =>
            page.page.map((message, index) => {
              return <ChatBubble key={index} message={message} />
            })
          )
        }
      </ul>

      {hasNextPage &&
        <div ref={scrollTriggerRef} className={`inline-flex text-md text-gray-400 mx-auto items-center p-4`}>
          <ArrowPathIcon className="mr-2 h-4 w-4 animate-spin" />
          Loading messages&hellip;
        </div>
      }

      <div className="!m-0" ref={scrollTargetRef}></div>
    </>
  )
}

export default ChatMessages
