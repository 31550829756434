import { sendMessageSchema } from "../../lib/validations/messages"
import { zodResolver } from "@hookform/resolvers/zod"
import { PaperAirplaneIcon } from "@heroicons/react/24/outline"
import * as z from "zod"
import { useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import ApiClient from "../../lib/api/api-client"
import { useThemeContext } from "../../contexts/theme-context"
import { useEffect } from "preact/hooks"
import { useAppContext } from "../../contexts/app-context"

type FormData = z.infer<typeof sendMessageSchema>

interface ChatFormProps {
  scrollTargetRef: React.RefObject<HTMLDivElement>
  className?: string
}

function ChatForm({ scrollTargetRef, className }: ChatFormProps) {
  const queryClient = useQueryClient()
  const theme = useThemeContext()
  const { inSparkfire, channel, isSmallScreen } = useAppContext()

  const { register, handleSubmit, reset, formState: { errors }, setFocus } = useForm<FormData>({
    resolver: zodResolver(sendMessageSchema),
  })

  const { mutateAsync: sendMessage, isLoading: isSending } = useMutation({
    mutationFn: (data: FormData) => {
      return ApiClient().post(`/messages`, {
        ...data,
        ...(inSparkfire && channel === 'sms'  ? { simulated_channel: channel } : null),
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['messages'] })
    }
  })

  function onSubmit(data: FormData) {
    sendMessage(data)
      .catch((error) => {
        console.error("Error sending message", error)
      })
      .finally(() => {
        reset()
        setTimeout(() => {
          scrollTargetRef.current?.scrollIntoView({ behavior: "smooth" })
        }, 100)
      })
  }

  const handleInput = (event) => {
    event.target.style.height = 'auto'
    event.target.style.height = `${event.target.scrollHeight}px`
  }

  useEffect(() => {
    if (isSmallScreen) return
    setFocus('text')
  }, [setFocus, isSmallScreen])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={`flex gap-2 text-base font-normal leading-7 ${isSending ? 'pointer-events-none opacity-60' : ''} ${isSmallScreen && "absolute w-full bottom-0"} ${className}`}>
      <div className={`flex w-full ${errors['text'] && 'flex-col'}`}>
        <textarea
          placeholder="Ask any question"
          className="pl-7 py-4 rounded-md max-h-48 flex-1 focus:outline-none resize-none text-base"
          onInput={handleInput}
          {...register('text')}
          rows={1}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault()
              if (isSending) return
              handleSubmit(onSubmit)()
            }
          }}
        />
        {errors['text'] && errors['text'].message &&
          <p className="pl-7 pb-4 text-sm text-red-600">{errors['text'].message}</p>
        }
      </div>
      <button
        type="submit"
        className="text-gray-500 px-4 dynamic-hover"
        style={{
          transition: 'color 0.3s ease',
          ['--hover-color']: theme.primary_background_color
        }}
      >
        <PaperAirplaneIcon className="h-6 w-6" />
      </button>
    </form>
  )
}

export default ChatForm
