import { useEffect, useState } from "preact/hooks"
import Dialog from "./dialog"
import { useThemeContext } from "../../contexts/theme-context"

interface SwitchProps {
  enabled: boolean;
  onToggle: (active: boolean) => void
}

const Switch = ({ enabled, onToggle }: SwitchProps) => {
  const [isEnabled, setIsEnabled] = useState(enabled)
  const [dialogOpen, setDialogOpen] = useState(false)
  const theme = useThemeContext()

  useEffect(() => {
    setIsEnabled(enabled)
  }, [enabled])

  return (
    <>
      <button
        className={`relative w-16 transition duration-200 ease-in rounded-full inline-flex items-center py-1 px-2 border-[1px]`}
        onClick={() => {
          setDialogOpen(true)
        }}
        style={{
          backgroundColor: isEnabled ? theme.primary_background_color : "#e5e7eb",
        }}
      >
        <span
          className={`${isEnabled ? 'translate-x-8' : ''} inline-block h-4 w-4 transform rounded-full bg-white transition`}
        />
        <span className={`absolute text-xs transition ease-in font-light ${isEnabled ? '' : 'right-2 text-gray-600'}`}>
          {isEnabled ? 'SMS' : 'WEB'}
        </span>
      </button>

      <Dialog
        isOpen={dialogOpen}
        setIsOpen={setDialogOpen}
        title={`Switch channel to ${isEnabled ? 'webchat' : 'SMS'}?`}
        message='This will reset the chat and allow you to test your campaign through this channel.'
        onConfirm={() => {
          onToggle(!isEnabled)
          setIsEnabled(prevState => !prevState)
        }}
        onCancel={() => setDialogOpen(false)}
      />
    </>
  )
}

export default Switch
