
import useLocalStorage from '../hooks/use-local-storage'
import useScreenSize from '../hooks/use-screen-size'
import { createContext } from 'preact'
import { StateUpdater, useContext, useState } from 'preact/hooks'
import { useMessagesListener } from '../hooks/use-messages-listener'

interface AppContextType {
  inSparkfire: boolean
  isSmallScreen: boolean
  unreadMessagesCount: number
  setUnreadMessagesCount: StateUpdater<number>
  hasInboundMessages: boolean
  setHasInboundMessages: StateUpdater<boolean>
  channel: string
  setChannel: StateUpdater<string>,
  ended: boolean
  setEnded: StateUpdater<boolean>
  hasContainer: boolean
  isDelayed: boolean
  setIsDelayed: StateUpdater<boolean>
}

const initialState: AppContextType = {
  inSparkfire: false,
  isSmallScreen: false,
  unreadMessagesCount: 0,
  setUnreadMessagesCount: () => { },
  hasInboundMessages: false,
  setHasInboundMessages: () => { },
  channel: 'webchat',
  setChannel: () => { },
  ended: false,
  setEnded: () => { },
  hasContainer: false,
  isDelayed: false,
  setIsDelayed: () => { }
}

export const AppContext = createContext(initialState)

export const AppContextProvider = ({ children }: { children }) => {
  const urlParams = new URLSearchParams(window.location.search)
  const { sparkfireToken } = useMessagesListener()
  const { isSmallScreen } = useScreenSize()
  const [unreadMessagesCount, setUnreadMessagesCount] = useLocalStorage('unreadMessagesCount', 0)
  const [hasInboundMessages, setHasInboundMessages] = useLocalStorage('hasInboundMessages', false)
  const [channel, setChannel] = useLocalStorage('channel', 'webchat')
  const [ended, setEnded] = useState(false)
  const [isDelayed, setIsDelayed] = useState(false)
  
  const hasContainer = JSON.parse(urlParams.get('hasContainer'))

  return (
    <AppContext.Provider value={{
      inSparkfire: !!sparkfireToken,
      isSmallScreen: isSmallScreen,
      unreadMessagesCount: unreadMessagesCount,
      setUnreadMessagesCount: setUnreadMessagesCount,
      hasInboundMessages: hasInboundMessages,
      setHasInboundMessages: setHasInboundMessages,
      channel: channel,
      setChannel: setChannel,
      ended: ended,
      setEnded: setEnded,
      hasContainer: hasContainer,
      isDelayed: isDelayed,
      setIsDelayed: setIsDelayed
    }}>
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => useContext(AppContext)
