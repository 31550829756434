import { ChatWidget } from "@/lib/types"
import ChatForm from "./chat-form"
import { useThemeContext } from "../../contexts/theme-context"
import { StateUpdater, useEffect, useRef } from "preact/hooks"
import ChatMessages from "./chat-messages"
import StartOverButton from "../start-over-button"
import { useAppContext } from "../../contexts/app-context"
import ToggleOpenButton from "../toggle-open-button"
import Switch from "../ui/switch"
import Tooltip from "../ui/tooltip"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import ApiClient from "../../lib/api/api-client"
import useCurrentContact from "../../hooks/use-current-contact"

interface ChatProps {
  widgetConfig: ChatWidget
  isChatOpen: boolean
  toggleIsChatOpen: () => void
  isGeneratingResponse: boolean
  setIsGeneratingResponse: StateUpdater<boolean>
}

function Chat({ widgetConfig, isChatOpen, toggleIsChatOpen, isGeneratingResponse, setIsGeneratingResponse }: ChatProps) {
  const theme = useThemeContext()
  const { inSparkfire } = useAppContext()
  const scrollTargetRef = useRef<HTMLDivElement>(null)
  const { isSmallScreen, channel, setChannel, setEnded, ended, hasContainer, isDelayed } = useAppContext()
  const queryClient = useQueryClient()

  const { data: contact } = useCurrentContact()

  const { mutateAsync: resetContact } = useMutation({
    mutationFn: () => {
      return ApiClient().post(`/contacts/me/reset`)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['messages'] })
      queryClient.invalidateQueries({ queryKey: ['contacts', 'me'] })

      setIsGeneratingResponse(false)
    }
  })

  const toggleChannel = (isSMS) => {
    resetContact().then(() => {
      setChannel(isSMS ? 'sms' : 'webchat')
    })
  }

  useEffect(() => {
    if (!widgetConfig.enabled) {
      setChannel('sms')
    }
  }, [widgetConfig, setChannel])

  useEffect(() => {
    if (!contact) return

    if (contact.assigned_to == "ended") {
      setEnded(true)
    } else {
      setEnded(false)
    }
  }, [contact, setEnded])

  const chatDisabled = (ended || isDelayed)  && channel == 'webchat'

  return (
    <div className={`relative bg-white h-[100vh] ${!hasContainer && 'sm:h-[calc(100vh-5rem)] sm:max-h-[44rem] sm:shadow-lg sm:rounded-lg'}`}>
      <div
        className={`flex font-medium min-h-12 h-[7%] sm:h-[10%] items-center px-4 ${!hasContainer && 'sm:rounded-t-lg'}`}
        style={{
          backgroundColor: theme.primary_background_color,
          color: theme.primary_text_color
        }}>
        {isSmallScreen && !hasContainer && <ToggleOpenButton isChatOpen={isChatOpen} toggleIsChatOpen={toggleIsChatOpen} variant="mobile" />}
        {widgetConfig.title}
        {inSparkfire &&
          <div className="ml-auto flex items-center justify-end gap-x-4">
            {widgetConfig.enabled &&
              <Tooltip message="Switch Channel" position="bottom">
                <Switch
                  enabled={channel === 'sms'}
                  onToggle={toggleChannel}
                />
              </Tooltip>
            }
            <StartOverButton
              resetContact={resetContact}
            />
          </div>
        }
      </div>
      <div className="flex flex-col h-[93%] sm:h-[90%] justify-between divide-y divide-gray-300/50 border-t border-gray-300/50">
        <div className={`space-y-6 py-4 text-base leading-7 text-gray-600 overflow-y-auto ${isSmallScreen && 'mb-14'} overscroll-contain`}>
          <ChatMessages scrollTargetRef={scrollTargetRef} isGeneratingResponse={isGeneratingResponse} />
        </div>

        <ChatForm
          scrollTargetRef={scrollTargetRef}
          className={chatDisabled ? 'pointer-events-none opacity-60' : ''}
        />
      </div>
    </div>
  )
}

export default Chat
