import WebChat from './webchat'
import '../style/tailwind.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useEffect, useState } from 'preact/hooks'
import { AppContextProvider } from '../contexts/app-context'
import axios from 'axios'

const App = () => {

  const [queryClient] = useState(() =>
    new QueryClient({
      defaultOptions: {
        queries: {
          suspense: false,
        }
      }
    })
  )

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const webId = urlParams.get('_sfid')

    if (webId) {
      axios.defaults.headers['X-Sparkfire-Web-Id'] = webId
    }
  }, [])

  return (
    <div id="app">
      <QueryClientProvider client={queryClient}>
        <AppContextProvider>
          <WebChat />
        </AppContextProvider>
      </QueryClientProvider>
    </div>
  )
}

export default App
