import { useAppContext } from '../contexts/app-context'
import { useEffect, useState } from 'react'
import { InfiniteData } from '@tanstack/react-query'
import { Contact, Messages } from '@/lib/types'

export function useMessagesDelayTracker(messages: InfiniteData<Messages>, contact: Contact) {
  const { setIsDelayed } = useAppContext()
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)

  const checkDelay = () => {
    if (!messages || !contact) return
    const lastMessage = messages.pages[0].page[0]

    const calculateAndSetDelay = () => {
      const messageTimestamp = new Date(lastMessage.timestamp)
      const now = new Date()
      const diffTime = now.getTime() - messageTimestamp.getTime()

      if (diffTime > 120000) { // 2 minutes
        setIsDelayed(true)
      } else {
        setIsDelayed(false)
        clearTimeout(timeoutId)

        const remainingTime = 120000 - diffTime
        const id = setTimeout(checkDelay, remainingTime)

        setTimeoutId(id)
      }
    }

    if (lastMessage.direction === 'in' && contact.needs_review) {
      calculateAndSetDelay()
    } else if (lastMessage.direction === 'out') {
      setIsDelayed(false)
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }

  useEffect(() => {
    if (!messages || !contact) return

    checkDelay()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, contact])
}