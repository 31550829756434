import { useQuery, UseQueryResult } from "@tanstack/react-query"
import ApiClient from "../lib/api/api-client"
import { Contact } from "../lib/types"

export default function useCurrentContact(): UseQueryResult<Contact> {
  return useQuery({
    queryKey: ['contacts', 'me'],
    queryFn: () => ApiClient().get(`/contacts/me`)
  })
}
