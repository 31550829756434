import { useEffect, useState } from "preact/hooks"


export default function useScreenSize() {
  const [screenSize, setScreenSize] = useState(window.innerWidth)

  useEffect(() => {
    function handleResize() {
      setScreenSize(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

  }, [])

  return {
    isSmallScreen: screenSize < 447
  }
}