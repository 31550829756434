import { useState } from "preact/hooks"

export default function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = localStorage.getItem(key)

      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.error(`Failed to parse storage item "${key}".`)
      return initialValue
    }
  })

  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      
      localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      throw new Error(`Failed to set storage item "${key}".`)
    }
  }

  return [storedValue, setValue]
}