import { sendMessage } from '../lib/utils'
import { useState, useEffect } from 'preact/hooks'
import axios from 'axios'
import useWidgetConfig from './use-widget-config'
import ApiClient from '../lib/api/api-client'
import { useMutation } from '@tanstack/react-query'

export function useMessagesListener() {
  const [sparkfireToken, setSparkfireToken] = useState(null)
  const { data: widgetConfig } = useWidgetConfig()

  const { mutateAsync: identifyContact } = useMutation({
    mutationFn: (contact : { 
      first_name: string,
      last_name: string,
      email: string
    }) => {
      return ApiClient().post(`/contacts/me`, contact)
    },
    onSuccess: () => {
      sendMessage('identified')
    },
    onError: (error) => {
      sendMessage('identifyFailed', { error })
    }
  })

  useEffect(() => {
    if (!widgetConfig) return

    function handleReceiveMessage(event) {
      if (event.data.event_type == "set_app_token" && event.data.sparkfire_token) {
        setSparkfireToken(event.data.sparkfire_token)
      } else if (event.data.event_type == "identify") {
        identifyContact(event.data)
      }
    }
    
    window.addEventListener('message', handleReceiveMessage)

    // Notify parent we are loaded
    sendMessage('loaded')
  }, [identifyContact, widgetConfig])

  useEffect(() => {
    if (sparkfireToken) {
      axios.defaults.headers.Authorization = `Bearer ${sparkfireToken}`
    }
  }, [sparkfireToken])

  return { sparkfireToken }
}