import axios from 'axios'

const apiURL = process.env.PREACT_APP_API_URL

const baseURL = `${apiURL}/api/webchat`

const ApiClient = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const widget_id = urlParams.get('widget_id')

  if (!widget_id) {
    throw new Error('Invalid URL: widget_id is missing.')
  }

  axios.defaults.withCredentials = true

  const defaultOptions = {
    baseURL,
    headers: {
      'X-SparkFire-Widget-ID': widget_id,
    }
  }

  const instance = axios.create(defaultOptions)

  instance.interceptors.request.use(async (request) => {
    return request
  })

  instance.interceptors.response.use(
    (response) => {
      return response.data
    },
    (error) => {
      console.log("API Error", error)
      throw error
    },
  )

  return instance
}

export default ApiClient
