import { useEffect } from "react"
import { getMediaType } from "../../lib/utils"
import { DocumentTextIcon } from "@heroicons/react/24/outline"

interface MediaPreviewProps {
  url: string,
  mimeType: string
}

export const MediaPreview = ({ url, mimeType }: MediaPreviewProps) => {
  const mediaType = getMediaType(mimeType)

  useEffect(() => {
    if (mediaType === 'pdf' || mediaType === 'doc') {
      window.open(url, '_blank', 'noopener,noreferrer')
    }
  }, [url, mediaType])

  if (mediaType === 'audio') {
    return (
      <div className={`p-2 col-span-2 w-[12rem] md:w-[22rem] lg:max-w-xl`}>
        <audio controls className="w-full">
          <source src={url}
          />
        </audio>
      </div>
    )
  } else if (mediaType === 'video') {
    return (
      <div className="p-2 col-span-2">
        <video controls className={`${mimeType ? 'max-w-xs lg:max-w-xl' : ''}`}>
          <source src={url}
          />
        </video>
      </div>
    )
  }
  else if (mediaType === 'image') {
    return (
      <div className="p-2 col-span-1">
        <img
          src={url}
          alt="avatar"
          className="w-auto max-w-40 h-36 rounded-lg bg-gray-800 object-cover group-hover:opacity-30"
        />
      </div>
    )
  } else if (mediaType === 'pdf') {
    return (
      <div className="p-6 rounded-lg col-span-2 flex flex-col gap-y-2 items-center justify-center bg-white">
        <DocumentTextIcon className="w-12 h-12 text-red-800" />
        <p className="text-sm">PDF Document</p>
        <p className="text-xs text-gray-400">Your file should open automatically. If not, click here:</p>
        <a href={url} target="_blank" rel="noopener noreferrer" className="inline-flex rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          Open PDF
        </a>
      </div>
    )
  } else if (mediaType === 'doc') {
    return (
      <div className="p-6 rounded-lg col-span-2 flex flex-col gap-y-2 items-center justify-center bg-white">
        <DocumentTextIcon className="w-12 h-12 text-blue-500" />
        <p className="text-sm">DOCX Document</p>
        <p className="text-xs text-gray-400">Your file should download automatically. If not, click here:</p>
        <a href={url} target="_blank" rel="noopener noreferrer" className="inline-flex rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          Download DOCX
        </a>
      </div>
    )
  } else {
    return (
      <div className="p-2 col-span-2">
        <div className="text-center text-red-500">Error loading preview</div>
      </div>
    )
  }
}
