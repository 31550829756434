import { useThemeContext } from '../contexts/theme-context'
import { BTN_TRIGGER_CHAT_ID } from '../lib/constants'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { useAppContext } from '../contexts/app-context'

interface ToggleOpenButtonProps {
  isChatOpen: boolean
  toggleIsChatOpen: () => void
  variant?: "default" | "mobile"
}

const stylesByVariant = {
  default: "w-fit right px-3 py-3 rounded-full shadow-lg z-50 transform transition-transform duration-200 hover:scale-110",
  mobile: "px-3 py-2"
}

function ToggleOpenButton({ isChatOpen, toggleIsChatOpen, variant = "default" }: ToggleOpenButtonProps) {
  const theme = useThemeContext()
  const { isSmallScreen } = useAppContext()

  const hideButton = variant == "mobile" ? !isSmallScreen && !isChatOpen : isSmallScreen && isChatOpen
  const btnStyle = stylesByVariant[variant]

  return (
    <button
      id={BTN_TRIGGER_CHAT_ID}
      onClick={toggleIsChatOpen}
      className={btnStyle + (hideButton ? " hidden" : "")}
      style={{
        backgroundColor: theme.primary_background_color,
        color: theme.primary_text_color
      }}
    >
      {variant == "mobile" ?
        <span>
          <ChevronLeftIcon className="h-6 w-6" />
        </span>
        :
        <span>
          {isChatOpen ?
            <XMarkIcon className="h-6 w-6" />
            :
            <img
              src="./assets/icons/colorless-sparkfire-chat.png"
              alt="Chat"
              className="w-6 h-6"
            />
          }
        </span>
      }
    </button>
  )
}

export default ToggleOpenButton