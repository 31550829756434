import { PropsWithChildren } from 'react'

interface TooltipProps extends PropsWithChildren {
  message: string
  position?: 'top' | 'right' | 'bottom' | 'left'
}

const Tooltip = ({ children, message, position = 'top' } : TooltipProps) => {
  let positionClasses = ''

  
  switch (position) {
  case 'top':
    positionClasses = 'bottom-full mb-1 origin-bottom'
    break
  case 'right':
    positionClasses = 'right-full mr-1 origin-right'
    break
  case 'bottom':
    positionClasses = 'top-full mt-1 origin-top'
    break
  case 'left':
    positionClasses = 'left-full ml-1 origin-left'
    break
  }

  return (
    <div className="relative group flex justify-center items-center">
      {children}
      <div className={`absolute z-20 transition-all rounded bg-gray-800 p-2 text-xs text-white scale-0 group-hover:scale-100 w-max max-w-64 shadow-md ${positionClasses}`}>
        {message}
      </div>
    </div>
  )
}

export default Tooltip