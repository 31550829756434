import { useQuery, UseQueryResult } from "@tanstack/react-query"
import ApiClient from "../lib/api/api-client"
import { ChatWidget } from "@/lib/types"

export default function useWidgetConfig(): UseQueryResult<ChatWidget> {
  return useQuery({
    queryKey: ['chat_widget'],
    queryFn: () => ApiClient().get(`/widget`),
  })
}
