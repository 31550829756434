
import { createContext } from 'preact'
import { useContext } from 'preact/hooks'

const initialTheme = {
  primary_text_color: "#FFFFFF",
  primary_background_color: "#EA5E5B"
}

export const ThemeContext = createContext(initialTheme)

export const useThemeContext = () => useContext(ThemeContext)