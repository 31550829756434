import { useThemeContext } from "../../contexts/theme-context"
import { Message } from "@/lib/types"
import { formatTimestamp } from "../../lib/utils"
import { PropsWithChildren } from "preact/compat"
import { MediaPreview } from "../ui/media-preview"

interface ChatBubbleProps extends PropsWithChildren {
  message?: Message
  className?: string
}

function ChatBubble({ message, children, className }: ChatBubbleProps) {
  const theme = useThemeContext()

  const bubbleStyle = message?.direction === "in" && {
    backgroundColor: theme.primary_background_color,
    color: theme.primary_text_color,
  }

  return (
    <div
      className={`flex flex-col gap-y-1 justify-center ${message?.direction === "in" ? "ml-10 items-end" : "mr-10 items-start"}`}
    >
      <div className={`bg-gray-100 text-sm py-3 px-4 rounded-md ${className}`} style={bubbleStyle}>
        {message?.text ?
          <div dangerouslySetInnerHTML={{ __html: message.text }}></div> : children
        }
      </div>

      {message && message.timestamp &&
        <time className="text-xs opacity-50">{formatTimestamp(message.timestamp)}</time>
      }

      {message?.medias && message.medias.length > 0 &&
        <div className={`grid ${message.medias.length > 1 ? 'grid-cols-2' : 'grid-cols-1'}`} style={bubbleStyle}>
          {message.medias.map((media, index) => (
            <MediaPreview url={media.url} mimeType={media.mime_type} key={index} />
          ))}
        </div>
      }
    </div>
  )
}

export default ChatBubble