
function TermsPage() {
  const urlParams = new URLSearchParams(window.location.search)
  const widgetId = urlParams.get('widget_id') || "1"

  return (
    <div className="relative p-8 grid gap-y-6">
      {
        <a href={`/credits${location.search}`} className="text-sm px-4 py-2 mt-4 absolute top-8 right-8 hover:underline" data-native>
          See credits
        </a>
      }

      <h2>SparkFire Subscription Agreement</h2>

      <p>Effective: August 8, 2023</p>

      <p>This is a contract between you (the Customer) and us (SparkFire). It describes the services we will provide to you, how we will work together, and other aspects of our business relationship. By using the Subscription Service (as defined below) or receiving the Professional Services (as defined below), you are agreeing to these terms.</p>

      <h4 className="font-bold">GENERAL TERMS</h4>

      <h4 className="font-bold">1. Definitions</h4>
      <p>“Agreement” means this Subscription Agreement and any other agreements referenced or incorporated into this Subscription Agreement including any Order Forms or Statements of Work.</p>
      <p>“Billing Period” means the period for which you agree to prepay fees under an Order Form, which will be the same as or shorter than the Subscription Term. For example, if you subscribe to the Subscription Service for a one (1) year Initial Subscription Term, with a twelve (12) month upfront payment, the Billing Period will be twelve (12) months.</p>
      <p>“Communication Services” means third-party forums, online communities, blogs, personal web pages, calendars, and/or other social media communication facilities (such as Facebook, Twitter and LinkedIn) linked to or from the Subscription Service that enable you to communicate with the public or with a private group.</p>
      <p>“Confidential Information” means all information provided by you or us (“Discloser”) to the other (“Receiver”), whether orally or in writing that is designated at the time of disclosure as confidential. Confidential Information does not include any information that (i) is or becomes generally known to the public without breach of any obligation owed to the Discloser or (ii) was known to the Receiver before receipt from the Discloser. All non-public features of the Subscription Service and pricing information on the Order Form will be considered to be Confidential Information of SparkFire. All Customer Data will be considered to be Confidential Information of Customer.</p>
      <p>“Contact” means a voter, customer, prospect, lead, or other individual (other than a User) whose Contact Information is stored by you in the Subscription Service.</p>
      <p>“Contact Information” means the name, email address, phone number, online user name(s), telephone number, and similar information submitted by visitors to your landing pages on the Subscription Service or uploaded by you to the Subscription Service.</p>
      <p>“Customer Data” means all information that you submit or collect via the Subscription Service. Customer Data does not include Enrichment Data.</p>
      <p>“Customer Materials” means all materials that you provide or post, upload, input or submit for public display through the Subscription Service.</p>
      <p>“Enrichment Data” means the data we make available to you as part of the Subscription Service, including data you may provide to us as described in the ‘Company Data’ section in the ‘CRM’ provisions below. Enrichment Data also includes information about Users, such as social media handles, avatars, and alternate email address, that we obtain from public or third party sources.</p>
      <p>“Order Form” or “Order” means the SparkFire-approved form or online subscription process by which you agree to subscribe to the Subscription Service and purchase Professional Services. Most Orders are completed through our online payment process or via in-app purchase. The purchase form may be referred to as a “Statement of Work” if you are purchasing only Professional Services.</p>
      <p>“Professional Services” means the services provided to you by us, which may include training services, installation, integration, or other consulting services.</p>
      <p>“Quarter” means a period of three consecutive months starting on the initial Subscription Term.</p>
      <p>“Sensitive Information” means credit or debit card numbers; personal financial account information; Social Security numbers; passport numbers; driver’s license numbers or similar identifiers; racial or ethnic origin; physical or mental health condition or information; or other employment, financial or health information, including any information designated as “sensitive” or any similar classification under any regulations, laws or industry standards designed to protect data privacy and security, such as the Health Insurance Portability and Accountability Act and the Payment Card Industry Data Security Standards.</p>
      <p>“SparkFire Content” means all information, data, text, messages, software, sound, music, video, photographs, graphics, images, and tags that we incorporate into the Subscription Service or Professional Services, including Enrichment Data.</p>
      <p>“Subscription Fee” means the amount you pay for the Subscription Service.</p>
      <p>“Subscription Service” means all of our web-based marketing applications, tools and platforms that you have subscribed to by an Order Form or that we otherwise make available to you, and developed, operated, and maintained by us, whether accessible via <span>https://www.sparkfire.ai</span> or another designated URL, and any ancillary products and services, including website hosting, that we provide to you.</p>
      <p>“Subscription Term” means the Initial Subscription Term and all Renewal Subscription Terms.</p>
      <p>“Third-Party Products” means non-embedded products, websites and services that are provided by third parties which interoperate with or are used in connection with the Subscription Service. These products and services include non-SparkFire apps available from, for example, the following: our integrations products page, partner directory, links made available through the Subscription Service and non-SparkFire services, as well as third-party websites linked from within the Subscription Service, including Communications Services.</p>
      <p>“SparkFire”, “we”, “us” or “our” means SparkFire Technologies, LLC, a Delaware corporation.</p>
      <p>“Users” means your employees, representatives, consultants, contractors or agents who are authorized to use the Subscription Service for your benefit and have unique user identifications and passwords for the Subscription Service.</p>
      <p>“You”, “your” or “Customer” means the person or entity using the Subscription Service or receiving the Professional Services and identified in the applicable billing statement, online subscription process, Order Form or Statement of Work as the customer.</p>
      <h4 className="font-bold">2. The Subscription Service</h4>
      <ol className="list-decimal list-inside space-y-1">
        <li>Access. During the Subscription Term, we will provide you access to use the Subscription Service as described in this Agreement.</li>
        <li>Limits. The limits that apply to you will be specified in your Order Form, this Agreement or on our Pricing Page.</li>
        <li>If we make modifications to the limits that would negatively impact you, these modifications will not apply to you until the start of your next Renewal Subscription Term. On renewal, the current product usage limits on our will apply to your subscription, unless you and we otherwise agree.</li>
        <li>Modifications. We may modify the Subscription Service from time to time, including by adding or deleting features and functions, in an effort to improve your experience. But we will not make changes to the Subscription Service that materially reduce the functionality of the Subscription Service provided to you during the Subscription Term. We might provide some or all elements of the Subscription Service through third party service providers.</li>
        <li>Additional Features. You may subscribe to additional features of the Subscription Service by agreeing to a new Order Form.</li>
      </ol>

      <h4 className="font-bold">3. Customer Support</h4>
      <ol className="list-decimal list-inside space-y-1">
        <li>Support. Support is included in your Subscription Fee. Phone support for the Subscription Service is available 8AM to 8PM Eastern Time (GMT-5), Monday through Friday, excluding US national holidays.</li>
        <li>Availability. We try to make the Subscription Service available 24 hours a day, 7 days a week, except for planned down-time for maintenance. We do not promise or guarantee any specific level of availability.</li>
      </ol>

      <h4 className="font-bold">4. Professional Services</h4>
      <p>You may purchase Professional Services through an Order Form or Statement of Work. Unless otherwise described in a Statement of Work or Order Form, the Professional Services we provide will be delivered in English. Fees for these Professional Services are in addition to your Subscription Fee. If you purchase Professional Services that recur, they will be considered part of your subscription and will renew in accordance with the ‘Renewal Subscription Term’ section below. Unless otherwise agreed, all Professional Services are performed remotely.</p>
      <p>For Professional Services performed on-site, you will reimburse us our reasonable cost for all expenses incurred in connection with the Professional Services. Any invoices or other requests for reimbursements will be due and payable within thirty (30) days of the date of the invoice.</p>
      <p>If there are a specific number of hours included in the Professional Services purchased, those hours will expire as indicated in the applicable description, which expiration period will commence upon purchase (the “Expiration Period”). If there are deliverables included in the Professional Services purchased, it is estimated that those deliverables will be completed within the time period indicated as the delivery period in the applicable description, which delivery period will commence upon purchase (the “Delivery Period”). If the Professional Services provided are not complete at the end of the Delivery Period due to your failure to make the necessary resources available to us or to perform your obligations, such Professional Services will be deemed to be complete at the end of the Delivery Period. If the Professional Services provided are not complete at the end of the Delivery Period due to our failure to make the necessary resources available to you or to perform our obligations, the Delivery Period will be extended to allow us to complete such Professional Services.</p>
      <p>We might provide some or all elements of the Professional Services through third party service providers. Professional Services are non-cancellable and all fees for Professional Services are non-refundable.</p>

      <h4 className="font-bold">5. Fees and Payments</h4>
      <ol className="list-decimal list-inside space-y-1">
        <li>Payment by credit or debit card or physical check. If you are paying by ACH, wire transfer, credit or debit card, you authorize us to charge your credit card or bank account for all fees payable during the Subscription Term. You further authorize us to use a third party to process payments, and consent to the disclosure of your payment information to such third party.</li>
        <li>Payment against invoice. If you are paying by invoice, we will invoice you no more than forty-five (45) days before the beginning of the Initial Subscription Term and each subsequent Billing Period, and other times during the Subscription Term when fees are payable. All amounts invoiced are due and payable within fifteen (15) days from the date of the invoice, unless otherwise specified in the Order Form.</li>
        <li>Payment Information. You will keep your contact information, billing information and credit card information (where applicable) up to date. All payment obligations are non-cancelable and all amounts paid are non-refundable, except as specifically provided for in this Agreement. All fees are due and payable in advance throughout the Subscription Term. If you are a SparkFire partner that purchases on behalf of a client, you agree to be responsible for the Order Form and to guarantee payment of all fees.</li>
        <li>Sales Tax. All fees are exclusive of taxes, which we will charge as applicable. You agree to pay any taxes applicable to your use of the Subscription Service and performance of Professional Services. You shall have no liability for any taxes based upon our gross revenues or net income. If you are required to deduct or withhold any tax, you must pay the amount deducted or withheld as required by law and pay us an additional amount so that we receive payment in full as if there were no deduction or withholding.</li>
      </ol>

      <h4 className="font-bold">6. Subscription Term and Renewal</h4>
      <ol className="list-decimal list-inside space-y-1">
        <li>Initial Subscription Term. The initial subscription term shall begin on the effective date of your subscription and expire at the end of the period selected during the subscription process (“Initial Subscription Term”).</li>
        <li>Renewal Subscription Term. Unless one of us gives the other written notice that it does not intend to renew the subscription, this Agreement will automatically renew for one year (“Renewal Subscription Term”) after the Initial Subscription Term and each succeeding Renewal Subscription Term. Written notice of non-renewal must be sent no more than ninety (90) days but no less than thirty (30) days in advance of the end of the Subscription Term, unless otherwise set forth in your Order Form. The Renewal Subscription Term will be on the current terms and conditions of this Agreement, and subject to the renewal pricing provided for in your Order Form or, if not specified in the Order Form, on our standard pricing available on our Pricing Page. Should you decide not to renew, you may send the notice of non-renewal by email to cs@sparkfire.ai.</li>
        <li>End of Subscription Term. The Subscription Term will end on the expiration date and cannot be canceled before its expiration.</li>
      </ol>

      <h4 className="font-bold">7. SparkFire’s Proprietary Rights</h4>
      <p>This is an Agreement for access to and use of the Subscription Service, and you are not granted a license to any software by this Agreement. The Subscription Service and Professional Services are protected by intellectual property laws, they belong to and are the property of us or our licensors (if any), and we retain all ownership rights to them. You agree not to copy, rent, lease, sell, distribute, or create derivative works based on the SparkFire Content, the Subscription Service, or the Professional Services in whole or in part, by any means, except as expressly authorized in writing by us.</p>
      <p>If we make Enrichment Data available to you, then you may only use that Enrichment Data in connection with your use of the Subscription Service (unless, of course, you have a source other than the Subscription Service for such Enrichment Data.) The Enrichment Data we provide may be provided from or through third party service providers or public sources.</p>
      <p>We encourage all customers to comment on the Subscription Service or Professional Services, provide suggestions for improving it, and vote on suggestions they like. You agree that all such comments and suggestions will be non-confidential and that we own all rights to use and incorporate them into the Subscription Service or Professional Services, without payment or attribution to you.</p>
      <h4 className="font-bold">8. Customer’s Proprietary Rights</h4>
      <p>As between the parties, you own and retain all rights to the Customer Materials and Customer Data. This Agreement does not grant us any ownership rights to Customer Materials or Customer Data. You grant permission to us and our licensors to use the Customer Materials and Customer Data only as necessary to provide the Subscription Service and Professional Services to you and as permitted by this Agreement. If you are using the Subscription Service or receiving Professional Services on behalf of another party, then you represent and warrant that you have all sufficient and necessary rights and permissions to do so and to enter into this Agreement on behalf of such party.</p>
      <h4 className="font-bold">9. Confidentiality.</h4>
      <p>The Receiver will: (i) protect the confidentiality of the Confidential Information using the same degree of care that it uses with its own confidential information of similar nature, but with no less than reasonable care, (ii) not use any Confidential Information for any purpose outside the scope of this Agreement, (iii) not disclose Confidential Information to any third party (except, in SparkFire’s case, to our third party service providers as needed to provide the Subscription Service and Professional Services), and (iv) limit access to Confidential Information to its employees, contractors, advisors and agents with a need to know such information. Upon notice to the Discloser, the Receiver may disclose Confidential Information if required to do so under any federal, state, or local law, statute, rule or regulation, subpoena or legal process.</p>
      <h4 className="font-bold">10. Customer Responsibilities.</h4>
      <p>You are responsible under this Agreement for all conduct by your Users. Additionally, you will notify us right away of any unauthorized use of your Users’ identifications and passwords or your account by emailing us at support@sparkfire.ai.</p>
      <h4 className="font-bold">11. Publicity</h4>
      <p>You grant us the right to add your name and company logo to our customer list and website.</p>

      <h4 className="font-bold">12. Customer Data</h4>
      <ol className="list-decimal list-inside space-y-1">
        <li>Limits on SparkFire. We will not use, or allow anyone else to use, Customer Data to contact any individual or company except as you direct or otherwise permit. We will use Customer Data only in order to provide the Subscription Service and Professional Services to you and only as permitted by applicable law, this Agreement, and our Privacy Policy.</li>
        <li>Aggregate Data. We may monitor use of the Subscription Service by all of our customers and use the data gathered in an aggregate and anonymous manner. You agree that we may use and publish such information, provided that such information does not incorporate any Customer Data and/or identify you.</li>
        <li>Safeguards. We will maintain commercially appropriate administrative, physical, and technical safeguards to protect Customer Data. You consent to the processing of Customer Data in the United States.<span>No Sensitive Information. YOU AGREE NOT TO USE THE SUBSCRIPTION SERVICE TO COLLECT, MANAGE OR PROCESS SENSITIVE INFORMATION. WE WILL NOT HAVE ANY LIABILITY THAT MAY RESULT FROM YOUR USE OF THE SUBSCRIPTION SERVICE TO COLLECT OR MANAGE SENSITIVE INFORMATION.</span></li>
      </ol>

      <h4 className="font-bold">13. Use and Limitations of Use</h4>
      <p>Prohibited and Unauthorized Use. You will not (i) use or launch any automated system, including, “robots,” “spiders,” or “offline readers,” that sends more request messages to our servers in a given period of time than a human can reasonably produce in the same period by using a conventional browser; (ii) use the Subscription Service in any manner that damages, disables, overburdens, or impairs any of our systems or interferes with any other party’s use of the Subscription Service; (iii) attempt to gain unauthorized access to the Subscription Service; (iv) access the Subscription Service other than through our officially supported interfaces and APIs; or (v) use the Subscription Service for any purpose or in any manner that is unlawful or prohibited by this Agreement.</p>

      <h4 className="font-bold">14. Acceptable Use of Communications Services</h4>
      <p>You agree to use Communication Services only in compliance with any terms of use specified by each Communication Service. We do not control the content, messages or information found in the Communication Services. We will not have any liability with regards to the Communication Services and any actions resulting from your use of the Communication Services.</p>

      <h4 className="font-bold">15. Third-Party Sites and Products</h4>
      <p>Third-Party Products are not under our control. Third-Party Products are provided to you only as a convenience, and the availability of any Third-Party Product does not mean we endorse, support or warranty the Third-Party Product.</p>

      <h4 className="font-bold">16. Third-Party Data Providers</h4>
      <p>We may make use of third-party services to obtain data made available in the Subscription Service.</p>

      <h4 className="font-bold">17. Indemnification</h4>
      <p>Each party (as applicable, the &quot;Indemnifying Party&quot;) will indemnify, defend and hold the other party (as applicable, the &quot;Indemnified Party&quot;) harmless, at the Indemnifying Party’s expense, against any third-party claim, suit, action, or proceeding (each, an “Action”) brought against the Indemnified Party (and its respective officers, directors, employees, agents, service providers, licensors, and affiliates) by a third party not affiliated with the Indemnifying Party to the extent that such Action is based upon or arises out of (a) unauthorized or illegal use of the Subscription Service by the Indemnifying Party; (b) the Indemnifying Party’s noncompliance with or breach of this Agreement, (c) the Indemnifying Party’s use of Third-Party Products, or (d) the unauthorized use of the Subscription Service by any other person using the Indemnifying Party’s User information. The Indemnified Party will: notify the Indemnifying Party in writing within thirty (30) days of the Indemnified Party becoming aware of any such claim; give the Indemnifying Party sole control of the defense or settlement of such a claim; and provide the Indemnifying Party (at your expense) with any and all information and assistance reasonably requested by the Indemnifying Party to handle the defense or settlement of the claim. The Indemnifying Party shall not accept any settlement that (i) imposes an obligation on the Indemnified Party; (ii) requires the Indemnified Party to make an admission; or (iii) imposes liability not covered by these indemnifications or places restrictions on the Indemnified Party without such party’s prior written consent.</p>

      <h4 className="font-bold">18. Disclaimers; Limitations of Liability</h4>
      <ol className="list-decimal list-inside space-y-1">
        <li>Disclaimer of Warranties. WE AND OUR AFFILIATES AND AGENTS MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, SECURITY OR ACCURACY OF THE SUBSCRIPTION SERVICE, DATA MADE AVAILABLE FROM THE SUBSCRIPTION SERVICE, SPARKFIRE CONTENT, OR THE PROFESSIONAL SERVICES FOR ANY PURPOSE. APPLICATION PROGRAMMING INTERFACES (APIs) MAY NOT BE AVAILABLE AT ALL TIMES. TO THE EXTENT PERMITTED BY LAW, THE SUBSCRIPTION SERVICE, SPARKFIRE CONTENT AND PROFESSIONAL SERVICES ARE PROVIDED “AS IS” WITHOUT WARRANTY OR CONDITION OF ANY KIND. WE DISCLAIM ALL WARRANTIES AND CONDITIONS OF ANY KIND WITH REGARD TO THE SUBSCRIPTION SERVICE, AND THE PROFESSIONAL SERVICES INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</li>
        <li>No Indirect Damages. TO THE EXTENT PERMITTED BY LAW, IN NO EVENT SHALL EITHER PARTY BE LIABLE FOR ANY INDIRECT, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS OR BUSINESS OPPORTUNITIES.</li>
        <li>Limitation of Liability. EXCEPT FOR YOUR LIABILITY FOR PAYMENT OF FEES, YOUR LIABILITY ARISING FROM YOUR OBLIGATIONS UNDER THE ‘INDEMNIFICATION’ SECTION, AND YOUR LIABILITY FOR VIOLATION OF OUR INTELLECTUAL PROPERTY RIGHTS, IF, NOTWITHSTANDING THE OTHER TERMS OF THIS AGREEMENT, EITHER PARTY IS DETERMINED TO HAVE ANY LIABILITY TO THE OTHER PARTY OR ANY THIRD PARTY, THE PARTIES AGREE THAT THE AGGREGATE LIABILITY OF A PARTY WILL BE LIMITED TO THE LESSER OF FIVE THOUSAND DOLLARS OR THE TOTAL AMOUNTS YOU HAVE ACTUALLY PAID FOR THE SUBSCRIPTION SERVICE IN THE TWELVE MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO A CLAIM.</li>
        <li>Third Party Products. WE DISCLAIM ALL LIABILITY WITH RESPECT TO THIRD-PARTY PRODUCTS THAT YOU USE. OUR LICENSORS SHALL HAVE NO LIABILITY OF ANY KIND UNDER THIS AGREEMENT. YOU UNDERSTAND AND AGREE THAT ABSENT YOUR AGREEMENT TO THE LIMITATIONS OF LIABILITY IN THIS SECTION 17, WE WOULD NOT PROVIDE THE SUBSCRIPTION SERVICE TO YOU.</li>
      </ol>

      <h4 className="font-bold">19. Termination, Suspension and Expiration</h4>
      <ol className="list-decimal list-inside space-y-1">
        <li>Termination for Cause. Either party may terminate this Agreement for cause, as to any or all Subscription Services: (i) upon thirty (30) days’ notice to the other party of a material breach if such breach remains uncured at the expiration of such period, or (ii) immediately, if the other party becomes the subject of a petition in bankruptcy or any other proceeding relating to insolvency, liquidation or assignment for the benefit of creditors. This Agreement may not otherwise be terminated prior to the end of the Subscription Term.</li>
        <li>Suspension for Prohibited Acts. We may suspend any User’s access to any or all Subscription Services for: (i) use of the Subscription Service in a way that violates applicable local, state, federal, or foreign laws or regulations or the terms of this Agreement, (ii) use of the SparkFire email send and social messaging service that results in excessive bounce-backs, SPAM notices or requests for removal from a mailing list by recipients, or (iii) repeated instances of posting or uploading material that infringes or is alleged to infringe on the copyright or trademark rights of any person or entity.</li>
        <li>Suspension for Non-Payment. We will provide you with notice of non-payment of any amount due. Unless the full amount has been paid, we may suspend your access to any or all of the Subscription Services ten (10) days after such notice. We will not suspend the Subscription Service while you are disputing the applicable charges reasonably and in good faith and are cooperating diligently to resolve the dispute. If a Subscription Service is suspended for non-payment, we may charge a re-activation fee to reinstate the Subscription Service.</li>
        <li>Suspension for Present Harm. If your website on, or use of, the Subscription Service: (i) is being subjected to denial of service attacks or other disruptive activity, (ii) is being used to engage in denial of service attacks or other disruptive activity, (iii) is creating a security vulnerability for the Subscription Service or others, (iv) is consuming excessive bandwidth, or (v) is causing harm to us or others, then we may, with electronic or telephonic notice to you, suspend all or any access to the Subscription Service. We will try to limit the suspension to the affected portion of the Subscription Service and promptly resolve the issues causing the suspension of the Subscription Service.</li>
        <li>Effect of Termination or Expiration. Upon termination or expiration of this Agreement, you will stop all use of the affected Subscription Service and SparkFire Content, and if we request, you will provide us written confirmation that you have discontinued all use of Enrichment Data (unless, of course, you have a source other than the Subscription Service for such Enrichment Data.) If you terminate this Agreement for cause, we will promptly refund any prepaid but unused fees covering use of the Subscription Service after termination. If we terminate this Agreement for cause, you will promptly pay all unpaid fees due through the end of the Subscription Term. Fees are otherwise non-refundable.</li>
        <li>Retrieval of Customer Data. As long as you have paid all fees owed to us, if you make a written request within thirty (30) days after termination or expiration of your subscription, we will provide you with temporary access to the Subscription Service to retrieve, or we will provide you with copies of, all Customer Data then in our possession or control. If we provide you with temporary access to the portal, we may charge a re-activation fee. We may withhold access to Customer Data until you pay any fees owed to us. Thirty (30) days after termination or expiration of your subscription, we will have no obligation to maintain or provide you the Customer Data and may, unless legally prohibited, delete all Customer Data in our systems or otherwise in our control.</li>
      </ol>

      <h4 className="font-bold">20. General</h4>
      <ol className="list-decimal list-inside space-y-1">
        <li>Amendment; No Waiver. We may update and change any part or all of this Subscription Agreement, including the fees and charges associated with the use of the Subscription Service (but, your fees and charges won’t change during the Subscription Term except as we explain in the ‘Fees and Payments’ section above.) If we update or change this Subscription Agreement, the updated Subscription Agreement we will let you know through the Notification app in the SparkFire portal used to access your SparkFire subscription. When we change this Subscription Agreement, the “Last Modified” date above will be updated to reflect the date of the most recent version. We encourage you to review this Subscription Agreement periodically. Your subscription will continue to be governed by the terms and conditions of the Subscription Agreement prior to modification for the remainder of your current term. Upon renewal, the Subscription Agreement published by us on our website will apply to the renewal term.</li>
        <li>No delay in exercising any right or remedy or failure to object will be a waiver of such right or remedy or any other right or remedy. A waiver on one occasion will not be a waiver of any right or remedy on any future occasion.</li>
        <li>Governing Law and Venue. Both parties consent to the exclusive jurisdiction and venue of the courts in Atlanta, Georgia, U.S.A. The State of Georgia’s laws, except for conflict of laws rules, will apply to any dispute related to this Agreement, the Subscription Service or Professional Services. Any dispute related to this Agreement, the Subscription Service or Professional Services will be decided by the state and federal courts in Fulton County, Georgia, and each party will be subject to the jurisdiction of those courts.</li>
        <li>Force Majeure. Neither party will be responsible for failure or delay of performance if caused by: an act of war, hostility, or sabotage; act of God; electrical, internet, or telecommunication outage that is not caused by the obligated party; government restrictions; or other event outside the reasonable control of the obligated party. Each party will use reasonable efforts to mitigate the effect of a force majeure event.</li>
        <li>Actions Permitted. Except for actions for nonpayment or breach of a party’s proprietary rights, no action, regardless of form, arising out of or relating to this Agreement may be brought by either party more than one (1) year after the cause of action has accrued.</li>
        <li>Relationship of the Parties. You and we agree that no joint venture, partnership, employment, or agency relationship exists between us.</li>
        <li>Law Enforcement Requests and Compliance with Law. We reserve the right at all times to disclose any information as necessary to satisfy any law, regulation, legal process or governmental request.</li>
        <li>You will not use SparkFire’s Subscription Service or Professional Services to engage in any activity that is illegal, deceptive, or harmful.  This includes not violating any relevant laws, regulations, governmental orders, or industry standards or guidance, including any that require (1) consent be obtained prior to transmitting, recording, collecting, or monitoring communications or (2) compliance with opt-out requests. Without limiting the foregoing, you will also acknowledge that you are responsible for complying with the Telephone Consumer Protection Act, the FTC’s Telemarketing Sales Rule, industry guidance on mobile messaging, and any all laws in your use of the Subscription Service and Professional Services, including any applicable export laws.</li>
        <li>Severability. If any part of this Agreement or an Order Form is determined to be invalid or unenforceable by applicable law, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of this Agreement will continue in effect.</li>
        <li>Notices. Notice will be sent to the contact address set forth herein (as such may be changed by notice given to the other party) and will be deemed delivered as of the date of actual receipt. To notify us, please send to SparkFire, LLC., 1100 Peachtree Street NE, Suite 1750, Atlanta, GA 30309</li>
        <li>To you: your address as provided in our SparkFire Subscription account information for you. We may give electronic notices by general notice via the Subscription Service and may give electronic notices specific to you by email to your e-mail address(es) on record in our account information for you or through the notifications center of the Subscription Service. We may give notice to you by telephone calls to the telephone numbers on record in our account information for you. You must keep all of your account information current.</li>
        <li>Entire Agreement. This Agreement (including each Order Form and Statement of Work) is the entire agreement between us for the Subscription Service and Professional Services and supersedes all other proposals and agreements, whether electronic, oral or written, between us. We object to and reject any additional or different terms proposed by you, including those contained in your purchase order, acceptance or website. Our obligations are not contingent on the delivery of any future functionality or features of the Subscription Service or dependent on any oral or written public comments made by us regarding future functionality or features of the Subscription Service. We might make versions of this Agreement available in languages other than English. If we do, the English version of this Agreement will govern our relationship and the translated version is provided for convenience only and will not be interpreted to modify the English version of this Agreement.</li>
        <li>Assignment. You will not assign or transfer this Agreement, including any assignment or transfer by reason of merger, reorganization, sale of all or substantially all of your assets, change of control or operation of law, without our prior written consent, which will not be unreasonably withheld. We may assign this Agreement to any affiliate or in the event of merger, reorganization, sale of all or substantially all of our assets, change of control or operation of law.</li>
        <li>No Third Party Beneficiaries. Nothing in this Agreement, express or implied, is intended to or shall confer upon any third party person or entity any right, benefit or remedy of any nature whatsoever under or by reason of this Agreement.</li>
        <li>Contract for Services. This Agreement is a contract for the provision of services and not a contract for the sale of goods. The provisions of the Uniform Commercial Code (UCC), the Uniform Computer Information Transaction Act (UCITA), or any substantially similar legislation as may be enacted, shall not apply to this Agreement. If you are located outside of the territory of the United States, the parties agree that the United Nations Convention on Contracts for the International Sale of Goods shall not govern this Agreement or the rights and obligations of the parties under this Agreement.</li>
        <li>Authority. Each party represents and warrants to the other that it has full power and authority to enter into this Agreement and that it is binding upon such party and enforceable in accordance with its terms.</li>
        <li>Survival. The following sections in the ‘General Terms’ shall survive the expiration or termination of this Agreement: ‘Definitions’, ‘Fees and Payments’, ‘SparkFire’s Proprietary Rights’, ‘Customer’s Proprietary Rights’, ‘Confidentiality’, ‘Publicity’, ‘Indemnification’, ‘Disclaimers; Limitations of Liability’, ‘Termination, Suspension and Expiration’, and ‘General’.</li>
        <li>Precedence. In the event of a conflict between the terms of this Agreement and an Order Form or Statement of Work, the terms of the Order Form or Statement of Work shall control, but only as to that Order Form or Statement of Work.</li>
      </ol>

      <script>{`(function(w, d, s, n, t, p) {w[n] = w[n] || function() {(w[n].q = w[n].q || []).push(arguments);};t = d.createElement(s); p = d.getElementsByTagName(s)[0]; t.id = s; t.async = true; t.src = 'https://cdn.sparkfire.ai/chat.js'; t.dataset.widgetId = ${widgetId}; p.parentNode.insertBefore(t, p);})(window, document, 'script', 'sparkfire');`}</script>
    </div>
  )
}

export default TermsPage