import { ArrowPathIcon, ChevronRightIcon } from "@heroicons/react/24/outline"
import ChatBubble from "./chat/chat-bubble"
import useCurrentContact from "../hooks/use-current-contact"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import ApiClient from "../lib/api/api-client"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import * as z from "zod"
import { updateContactEmailSchema } from "../lib/validations/contact"
import { useThemeContext } from "../contexts/theme-context"
import { useEffect, useState } from "preact/hooks"

type FormData = z.infer<typeof updateContactEmailSchema>

interface FollowUpProps {
  text: string
}

function FollowUp({ text }: FollowUpProps) {
  const { data: contact } = useCurrentContact()
  const queryClient = useQueryClient()
  const [isFormVisible, setIsFormVisible] = useState(false)

  const theme = useThemeContext()

  const { register, handleSubmit, reset } = useForm<FormData>({
    resolver: zodResolver(updateContactEmailSchema)
  })

  const { mutateAsync: updateContact, isLoading } = useMutation({
    mutationFn: (data: FormData) => {
      return ApiClient().post(`/contacts/me`, data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['contacts', 'me'] })
      reset()
      setIsFormVisible(false)
    }
  })

  async function onSubmit(data: FormData) {
    await updateContact({ email: data.email }).catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    if (!contact) return

    setIsFormVisible(!contact.follow_up_email)
  }, [contact])

  if (!contact) return null

  return (
    <>
      {contact.follow_up_email && !isFormVisible &&
        <ChatBubble className="bg-green-200">
          <p className="text-sm">
            Thank you! A team member will contact you shortly via this email: {contact.follow_up_email}. You can change this email by&nbsp;
            <span onClick={() => setIsFormVisible(true)} className={"underline cursor-pointer"}>entering a new one.</span>
          </p>
        </ChatBubble>
      }

      {isFormVisible &&
        <div className={`shadow-md px-5 py-6 border-[1px] rounded-md`}>
          <p className="text-sm mb-2">Email for follow-up:</p>
          <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-6">
            <input
              type="email"
              placeholder="email@example.com"
              className="col-span-5 px-3 py-1 border-solid border-[1px] rounded-s-md shadow-inner"
              {...register('email')}
            />
            <button className="col-span-1 p-3 rounded-e-md text-white flex justify-center" disabled={isLoading} style={{
              backgroundColor: theme.primary_background_color,
              color: theme.primary_text_color
            }}>
              {isLoading ?
                <ArrowPathIcon className="h-5 w-5 animate-spin" />
                :
                <ChevronRightIcon className="h-5 w-5" />
              }
            </button>
          </form>
        </div>
      }

      <ChatBubble
        message={{ direction: "out", text: text }}
      />
    </>
  )
}

export default FollowUp
