import { useSpring, animated } from "@react-spring/web"

interface ConfirmationDialogProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  title: string
  message: string
  onConfirm: () => void
  onCancel: () => void
}

const Dialog = ({ isOpen, setIsOpen, title, message, onConfirm, onCancel }: ConfirmationDialogProps) => {
  const animation = useSpring({
    config: { tension: 210, friction: 20, duration: 100 },
    opacity: isOpen ? 1 : 0,
    transformOrigin: 'bottom',
    display: isOpen ? 'block' : 'none'
  })

  if (!isOpen) {
    return null
  }

  const confirm = () => {
    onConfirm()
    setIsOpen(false)
  }

  return (
    <animated.div style={animation} className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-start justify-center pt-4 px-4 pb-20 text-center">
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all border">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {title}
                </h3>
                <div className="mt-2">
                  <p className="text-gray-500">
                    {message}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 flex justify-end gap-x-4">
            <button
              type="button"
              onClick={onCancel}
              className='px-4 py-2 rounded-md shadow-sm border border-input hover:bg-gray-100 text-black'
              style={{
                transition: 'color 0.3s ease, background-color 0.3s ease',
              }}
            >
              Cancel
            </button>
            <button
              onClick={confirm}
              className='px-4 py-2 rounded-md shadow-sm bg-indigo-700  hover:bg-indigo-500 focus-visible:outline-indigo-500 text-white'
              style={{
                transition: 'color 0.3s ease, background-color 0.3s ease',
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </animated.div>
  )
}

export default Dialog