import { sendMessage } from '../../lib/utils'
import { useThemeContext } from '../../contexts/theme-context'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { MutableRef } from 'preact/hooks'
import { useSpring, animated } from '@react-spring/web'
import { useAppContext } from '../../contexts/app-context'

interface PopupMessageProps {
  displayTime: number
  message: string
  containerRef: MutableRef<HTMLDivElement | null>
}

const PopupMessage = ({ displayTime, message, containerRef }: PopupMessageProps) => {
  const [isVisible, setIsVisible] = useState(false)
  const theme = useThemeContext()
  const { inSparkfire } = useAppContext()

  const animation = useSpring({
    config: { tension: 210, friction: 20 },
    from: { scale: 0, transformOrigin: 'bottom right' },
    to: { scale: isVisible ? 1 : 0 },
  })

  useEffect(() => {
    const wasClosed = localStorage.getItem('popupWasClosed')

    if (wasClosed) {
      return
    } else {
      setTimeout(() => {
        setIsVisible(true)
      }, displayTime)
    }
  }, [displayTime])

  useEffect(() => {
    if (containerRef.current && isVisible) {
      sendMessage('resize', {
        height: containerRef.current.clientHeight + 20,
        width: containerRef.current.clientWidth + 20,
      })
    }
  }, [isVisible, containerRef])

  const handleClose = () => {
    setIsVisible(false)
    localStorage.setItem('popupWasClosed', JSON.stringify(true))

    sendMessage('resize', {})
  }

  if (!isVisible || inSparkfire) return null

  return (
    <animated.div style={animation}>
      <div
        className="relative group bg-white p-2 rounded-lg text-sm dynamic-hover w-max with-tail max-w-56"
        style={{
          backgroundColor: theme.primary_background_color,
          color: theme.primary_text_color,
          boxShadow: "-10px 10px 25px -12px rgba(0,0,0,0.75)",
          ['--tail-color']: theme.primary_background_color
        }}
      >
        {message}
        <button onClick={handleClose}
          className="absolute right-[-0.5rem] top-[-0.5rem] text-lg p-1 rounded-full bg-gray-400 text-white hidden group-hover:block"
        >
          <XMarkIcon className="h-3 w-3" />
        </button>
      </div>
    </animated.div>
  )
}

export default PopupMessage