import './style'
import App from './components/app'
import TermsPage from './pages/Terms'
import { render } from 'preact'
import { Route, Router } from 'preact-router'
import CreditsPage from './pages/Credits'

render(
  <Router>
    <Route path="/" component={App} />
    <Route path="/terms" component={TermsPage} />
    <Route path="/credits" component={CreditsPage} />
  </Router>,
  document.body
)
