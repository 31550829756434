

function LoadingChat() {
  return (
    <div className="flex flex-col grow overflow-y-auto">
      <div className="p-4">
        <div className="flex items-center mb-4 gap-x-4">
          <div className="flex flex-col">
            <div className="w-40 h-4 bg-slate-300 rounded animate-pulse"></div>
            <div className="w-20 h-3 bg-slate-300 rounded animate-pulse mt-1"></div>
          </div>
        </div>
        <div className="flex items-center mb-4 gap-x-4 justify-end">
          <div className="flex flex-col items-end">
            <div className="w-60 h-4 bg-slate-300 rounded animate-pulse"></div>
            <div className="w-20 h-3 bg-slate-300 rounded animate-pulse mt-1"></div>
          </div>
        </div>
        <div className="flex items-center mb-4 gap-x-4">
          <div className="flex flex-col">
            <div className="w-80 h-4 bg-slate-300 rounded animate-pulse"></div>
            <div className="w-20 h-3 bg-slate-300 rounded animate-pulse mt-1"></div>
          </div>
        </div>
        <div className="flex items-center mb-4 gap-x-4 justify-end">
          <div className="flex flex-col items-end">
            <div className="w-40 h-4 bg-slate-300 rounded animate-pulse"></div>
            <div className="w-20 h-3 bg-slate-300 rounded animate-pulse mt-1"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoadingChat